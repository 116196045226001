/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Wir freuen uns, Ihnen mitteilen zu können, dass fehlzeitenerfassung.de ab dem nächsten Schuljahr startet."), "\n", React.createElement(_components.p, null, "fehlzeitenerfassung.de ist ein Tool zur Erfassung und Analyse von Fehlzeiten, um Schulabsentismus zu verhindern."), "\n", React.createElement(_components.p, null, "Wenn Sie Interesse an unserer Software zur Kontrolle von Schulabsentismus haben, können Sie ", React.createElement("a", {
    href: "https://fehlzeitenerfassung.de"
  }, " hier "), " mehr erfahren."), "\n", React.createElement(_components.p, null, "Wenn Sie die Software \"schueleranalyse.de\" aktiv betreiben und weiterhin Interesse an der Analyse und Kontrolle der Fehlzeiten\nIhrer Schüler haben, können Sie die neue Software, fehlzeitenerfassung.de, von eduFlux GmbH benutzen.\nKontaktieren Sie uns ", React.createElement("a", {
    href: "mailto:info@eduflux.de"
  }, " hier"), " um weitere Informationen zu erhalten."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
